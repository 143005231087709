import React, { useEffect, useState, useRef } from 'react';
import { ethers } from "ethers";
import {
  AcceptButton,
  StyledSpan,
  ItemText,
  ChallengeHeader,
  ProgressBarContainer,
  ProgressBar,
  StratagemIcon,
  StratagemsContainer,
  WeaponContainer,
  WeaponTypeLabel,
  WeaponNameLabel,
  WeaponIcon
} from "./index";
import config from '../config';
import challengeConfig from '../config/challengeConfig';

const ChallengeDisplay = ({ challenge, displayAccept, onAcceptChallenge }) => {
  const [remainingTime, setRemainingTime] = useState('');
  const [progress, setProgress] = useState(0);
  const [stratagemIconPaths, setStratagemIconPaths] = useState({});
  const [weaponIconPaths, setWeaponIconPaths] = useState({});
  const timeLabelRef = useRef(challenge.timestamp ? 'Estimated Time Remaining' : 'Rolls In');

  const currentBlockTimestamp = Date.now();

  useEffect(() => {
    const intervalId = setInterval(updateTimer, 1000);

    function updateTimer() {
      const currentTime = Date.now();
      let blocksRemaining;
      let totalDuration;

      if (challenge.phase === 'Judgement') {
        blocksRemaining = challenge.stats.judgmentExpireBlock - challenge.currentBlock;
        totalDuration = (challenge.stats.judgmentExpireBlock - challenge.stats.expireBlock) * config.averageBlockTime * 1000;
        timeLabelRef.current = 'Time Remaining';
      } else {
        blocksRemaining = ((challenge.id + challenge.eta) - challenge.currentBlock) ?
          (challenge.id + challenge.eta) - challenge.currentBlock : 0;
        totalDuration = challenge.eta * config.averageBlockTime * 1000;
        timeLabelRef.current = challenge.timestamp ? 'Time Remaining' : 'Rolls In';
      }

      const timeRemainingBase = blocksRemaining * config.averageBlockTime * 1000;
      let millisecondsRemaining = timeRemainingBase - (currentTime - currentBlockTimestamp);

      if (blocksRemaining + 1 > challenge.eta) {
        const totalChallengeTime = challenge.eta * config.averageBlockTime * 1000;
        if ((millisecondsRemaining - totalChallengeTime) >= 0) {
          timeLabelRef.current = 'Rolls In';
          millisecondsRemaining = (millisecondsRemaining - totalChallengeTime);
        } else {
          timeLabelRef.current = 'Time Remaining';
        }
      }

      if (millisecondsRemaining < 0) {
        setRemainingTime('00:00:00');
        setProgress(100);
        clearInterval(intervalId);
      } else {
        const hours = String(Math.floor(millisecondsRemaining / 3600000)).padStart(2, '0');
        const minutes = String(Math.floor((millisecondsRemaining % 3600000) / 60000)).padStart(2, '0');
        const seconds = String(Math.floor((millisecondsRemaining % 60000) / 1000)).padStart(2, '0');

        const calculatedProgress = Math.min(100 - ((millisecondsRemaining / totalDuration) * 100), 100);

        setRemainingTime(`${hours}:${minutes}:${seconds}`);

        if (blocksRemaining > challenge.eta) {
          setProgress(0);
        } else {
          setProgress(calculatedProgress);
        }
      }
    }

    updateTimer();
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [challenge.id, challenge.eta, challenge.currentBlock, challenge.phase, challenge.stats.expireBlock, challenge.stats.judgmentExpireBlock]);

  const loadIconPath = async (type, name) => {
    let path;

    if (type === 'stratagems') {
      path = challengeConfig.stratagems[name];
    } else if (type === 'default') {
      path = challengeConfig.weapons[type][name];
    } else {
      path = challengeConfig.weapons[type][name];
    }

    try {
      const module = await import(`../assets/helldivers2/${type === 'stratagems' ? 'icons/Helldivers-2-Stratagems-icons-svg' : 'weapons'}/${path}`);
      return module.default;
    } catch (error) {
      console.error(`Error loading icon for ${name}:`, error);
      return null;
    }
  };

  useEffect(() => {
    const loadIcons = async () => {
      const stratagemIcons = challenge.timestamp ?
        [challenge.loadout.stratagem1, challenge.loadout.stratagem2, challenge.loadout.stratagem3, challenge.loadout.stratagem4] :
        ['Super Earth Flag', 'Hellbomb', 'SOS Beacon', 'Reinforce'];

      const loadedStratagemIcons = {};
      for (const stratagem of stratagemIcons) {
        loadedStratagemIcons[stratagem] = await loadIconPath('stratagems', stratagem);
      }
      setStratagemIconPaths(loadedStratagemIcons);

      const loadedWeaponIcons = challenge.timestamp ? {
        primary: await loadIconPath('primary', challenge.loadout.primary),
        secondary: await loadIconPath('secondary', challenge.loadout.secondary),
        grenade: await loadIconPath('grenade', challenge.loadout.grenade)
      } : {
        primary: await loadIconPath('default', 'gun'),
        secondary: await loadIconPath('default','gun'),
        grenade: await loadIconPath('default', 'gun')
      };
      setWeaponIconPaths(loadedWeaponIcons);
    };

    loadIcons();
  }, [challenge]);

  return (
    <>
      <ChallengeHeader>
        Chaos Order {challenge.id}
      </ChallengeHeader>
      <ItemText>
        <StyledSpan color="#FC9403">Enemy Type:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.timestamp ? challenge.mission.enemy : 'TBD'}</StyledSpan>
      </ItemText>
      <ItemText>
        <StyledSpan color="#FC9403">Mission Difficulty:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.timestamp ? challenge.mission.difficulty : 'TBD'}</StyledSpan>
      </ItemText>
      <ItemText>
        <StyledSpan color="#FC9403">Mission Duration:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.timestamp ? challenge.mission.duration : 'TBD'}</StyledSpan>
      </ItemText>
      <ItemText>
        <StyledSpan color="#FC9403">Teamplay:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.timestamp ? challenge.mission.teamplay : 'TBD'}</StyledSpan>
      </ItemText>
      <ItemText>
        <StyledSpan color="#FC9403">Must Extract With:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.timestamp ? challenge.mission.stars : 'TBD'}</StyledSpan>
      </ItemText>
      {challenge.version === "1.0.0" && (
        <ItemText>
          <StyledSpan color="#FC9403">Player Must Extract:</StyledSpan>
          {' '}
          <StyledSpan color="white">{challenge.timestamp ? challenge.mission.extract : 'TBD'}</StyledSpan>
        </ItemText>
      )}
      <ChallengeHeader>Helldiver Loadout</ChallengeHeader>
      <ItemText>
        <StyledSpan color="#FC9403">Weapons:</StyledSpan>
      </ItemText>
      <StratagemsContainer>
        <WeaponContainer>
          <WeaponTypeLabel>Primary</WeaponTypeLabel>
          <WeaponIcon src={weaponIconPaths.primary} alt={challenge.timestamp ? challenge.loadout.primary : 'TBD'} />
          <WeaponNameLabel>{challenge.timestamp ? challenge.loadout.primary : 'TBD'}</WeaponNameLabel>
        </WeaponContainer>
        <WeaponContainer>
          <WeaponTypeLabel>Secondary</WeaponTypeLabel>
          <WeaponIcon src={weaponIconPaths.secondary} alt={challenge.timestamp ? challenge.loadout.secondary : 'TBD'} />
          <WeaponNameLabel>{challenge.timestamp ? challenge.loadout.secondary : 'TBD'}</WeaponNameLabel>
        </WeaponContainer>
        <WeaponContainer>
          <WeaponTypeLabel>Throwable</WeaponTypeLabel>
          <WeaponIcon src={weaponIconPaths.grenade} alt={challenge.timestamp ? challenge.loadout.grenade : 'TBD'} />
          <WeaponNameLabel>{challenge.timestamp ? challenge.loadout.grenade : 'TBD'}</WeaponNameLabel>
        </WeaponContainer>
      </StratagemsContainer>
      <ItemText>
        <StyledSpan color="#FC9403">Stratagems:</StyledSpan>
      </ItemText>
      <StratagemsContainer>
        {Object.entries(stratagemIconPaths).map(([name, icon], index) => (
          icon ? (
            <StratagemIcon
              key={index}
              src={icon}
              alt={name}
              title={name}
            />
          ) : (
            <div key={index}>{name}</div>
          )
        ))}
      </StratagemsContainer>
      <ChallengeHeader>Statistics</ChallengeHeader>
      <ItemText>
        <StyledSpan color="#FC9403">Phase:</StyledSpan>
        {' '}
        <StyledSpan color="white">{challenge.phase}</StyledSpan>
      </ItemText>
      <ItemText>
        <StyledSpan color="#FC9403">{timeLabelRef.current}:</StyledSpan>
        {' '}
        <StyledSpan color="white">{remainingTime}</StyledSpan>
      </ItemText>
      {(challenge.phase === 'Upcoming' || challenge.phase === 'Active' || challenge.phase === 'Judgement') && (
        <ProgressBarContainer>
          <ProgressBar progress={progress}>
            <span>{`${progress.toFixed(2)}%`}</span>
          </ProgressBar>
        </ProgressBarContainer>
      )}
      {challenge.stats.participants > 0 && (
        <>
          <ItemText>
            <StyledSpan color="#FC9403">Current Pot:</StyledSpan>
            {' '}
            <StyledSpan color="white">${ethers.utils.formatUnits(challenge.stats.pot, 6).toString()}</StyledSpan>
          </ItemText>
          <ItemText>
            <StyledSpan color="#FC9403">Potential Pot:</StyledSpan>
            {' '}
            <StyledSpan color="white">${ethers.utils.formatUnits(challenge.stats.total, 6).toString()}</StyledSpan>
          </ItemText>
          <ItemText>
            <StyledSpan color="#FC9403">Participants:</StyledSpan>
            {' '}
            <StyledSpan color="white">{challenge.stats.participants}</StyledSpan>
          </ItemText>
        </>
      )}
      {displayAccept && <AcceptButton onClick={onAcceptChallenge}>Accept Order</AcceptButton>}
    </>
  );
};

export default ChallengeDisplay;
