const conf = {
  "enemy": [
    "Terminid",
    "Automaton",
    "Illuminate"
  ],
  "difficulty": [
    "1 - Trivial or greater",
    "2 - Easy or greater",
    "3 - Medium or greater",
    "4 - Challenging or greater",
    "5 - Hard or greater",
    "6 - Extreme or greater",
    "7 - Suicide Mission or greater",
    "8 - Impossible or greater",
    "9 - Helldive or greater",
    "10 - Super Helldive"
  ],
  "duration": [
    "10 minute or more",
    "12 minute or more",
    "15 minute or more",
    "20 minute or more",
    "40 minute"
  ],
  "teamplay": [
    "Solo",
    "Squad"
  ],
  "teamplayWeights": [
    0.20,
    0.80
  ],
  "stars": [
    "0 stars or more",
    "1 stars or more",
    "2 stars or more",
    "3 stars or more",
    "4 stars or more",
    "5 stars"
  ],
  "primary": [
    "AR-23 Liberator",
    "AR-23A Liberator Carbine",
    "AR-23C Liberator Concussive",
    "AR-23P Liberator Penetrator",
    "AR-61 Tenderizer",
    "ARC-12 Blitzer",
    "BR-14 Adjudicator",
    "CB-9 Exploding Crossbow",
    "FLAM-66 Torcher",
    "JAR-5 Dominator",
    "LAS-16 Sickle",
    "LAS-5 Scythe",
    "MP-98 Knight",
    "Plas-1 Scorcher",
    "Plas-101 Purifier",
    "R-2124 Constitution",
    "R-36 Eruptor",
    "R-63 Diligence",
    "R-63CS Diligence Counter Sniper",
    "SG-20 Halt",
    "SG-225 Breaker",
    "SG-225IE Breaker Incendiary",
    "SG-225SP Breaker SPRAY&PRAY",
    "SG-451 Cookout",
    "SG-8 Punisher",
    "SG-8P Punisher Plasma",
    "SG-8S Slugger",
    "SMG-32 Reprimand",
    "SMG-37 Defender",
    "SMG-72 Pummeler",
    "StA-52 Assault Rifle",
    "StA-11 SMG",
    "PLAS-39 Accelerator Rifle",
    "LAS-17 Double-Edge Sickle",
  ],
  "secondary": [
    "GP-31 Grenade Pistol",
    "LAS-7 Dagger",
    "P-2 Peacemaker",
    "P-4 Senator",
    "P-11 Stim Pistol",
    "P-19 Redeemer",
    "P-72 Crisper",
    "P-113 Verdict",
    "PLAS-15 Loyalist",
    "SG-22 Bushwhacker",
    "CQC-19 Stun Lance",
    "CQC-30 Stun Baton",
    "CQC-5 Combat Hatchet",
    "GP-31 Ultimatum",
  ],
  "grenade": [
    "G-3 Smoke",
    "G-4 Gas Grenade",
    "G-6 Frag",
    "G-10 Incendiary",
    "G-12 High Explosive",
    "G-13 Incendiary Impact",
    "G-16 Impact",
    "G-23 Stun",
    "G-123 Thermite",
    "K-2 Throwing Knife",
    "G-50 Seeker",
  ],
  "stratagem": [
    "A/AC-8 Autocannon Sentry",
    "A/ARC-3 Tesla Tower",
    "A/G-16 Gatling Sentry",
    "A/M-12 Mortar Sentry",
    "A/M-23 EMS Mortar Sentry",
    "A/MG-43 Machine Gun Sentry",
    "A/MLS-4X Rocket Sentry",
    "AC-8 Autocannon",
    "APW-1 Anti-Material Rifle",
    "ARC-3 Arc Thrower",
    "AX/AR-23 Guard Dog",
    "AX/TX-13 Guard Dog Dog Breath",
    "AX/LAS-5 Guard Dog Rover",
    "B-1 Supply Pack",
    "E/MG-101 HMG Emplacement",
    "Eagle 110m Rocket Pods",
    "Eagle 500kg Bomb",
    "Eagle Airstrike",
    "Eagle Cluster Bomb",
    "Eagle Napalm Airstrike",
    "Eagle Smoke Strike",
    "Eagle Strafing Run",
    "EAT-17 Expendable Anti-Tank",
    "EXO-45 Patriot Exosuit",
    "EXO-49 Emancipator Exosuit",
    "FAF-14 Spear",
    "FLAM-40 Flamethrower",
    "FX-12 Shield Generator Relay",
    "GL-21 Grenade Launcher",
    "GR-8 Recoilless Rifle",
    "LAS-98 Laser Cannon",
    "LAS-99 Quasar Cannon",
    "LIFT-850 Jump Pack",
    "M-105 Stalwart",
    "MD-6 Anti-Personnel Minefield",
    "MD-17 Anti-Tank Mines",
    "MD-I4 Incendiary Mines",
    "MG-206 Heavy Machine Gun",
    "MG-43 Machine Gun",
    "MLS-4X Commando",
    "Orbital 120MM HE Barrage",
    "Orbital 380MM HE Barrage",
    "Orbital Airburst Strike",
    "Orbital EMS Strike",
    "Orbital Gas Strike",
    "Orbital Gatling Barage",
    "Orbital Laser",
    "Orbital Napalm Barrage",
    "Orbital Precision Strike",
    "Orbital Railcannon Strike",
    "Orbital Smoke Strike",
    "Orbital Walking Barrage",
    "RL-77 Airburst Rocket Launcher",
    "RS-422 Railgun",
    "SH-20 Balistic Shield Backpack",
    "SH-32 Shield Generator Pack",
    "TX-41 Sterilizer",
    "SH-51 Directional Shield",
    "A/FLAM-40 Flame Sentry",
    "E/AT-12 Anti-Tank Emplacement",
    "M-102 Fast Recon Vehicle",
    "StA-X3 W.A.S.P. Launcher",
    "Portable Hellbomb",
    "MD-8 Gas Mines",
  ],
  "BackpackStratagems": [
    "AC-8 Autocannon",
    "AX/AR-23 Guard Dog",
    "AX/TX-13 Guard Dog Dog Breath",
    "AX/LAS-5 Guard Dog Rover",
    "B-1 Supply Pack",
    "FAF-14 Spear",
    "FX-12 Shield Generator Relay",
    "GR-8 Recoilless Rifle",
    "LIFT-850 Jump Pack",
    "RL-77 Airburst Rocket Launcher",
    "SH-20 Balistic Shield Backpack",
    "SH-32 Shield Generator Pack",
    "SH-51 Directional Shield",
    "StA-X3 W.A.S.P. Launcher",
    "Portable Hellbomb",
  ],
  "SupportWeaponStratagems": [
    "AC-8 Autocannon",
    "APW-1 Anti-Material Rifle",
    "ARC-3 Arc Thrower",
    "EAT-17 Expendable Anti-Tank",
    "FAF-14 Spear",
    "FLAM-40 Flamethrower",
    "GL-21 Grenade Launcher",
    "GR-8 Recoilless Rifle",
    "LAS-98 Laser Cannon",
    "LAS-99 Quasar Cannon",
    "M-105 Stalwart",
    "MG-206 Heavy Machine Gun",
    "MG-43 Machine Gun",
    "MLS-4X Commando",
    "RL-77 Airburst Rocket Launcher",
    "RS-422 Railgun",
    "TX-41 Sterilizer",
    "StA-X3 W.A.S.P. Launcher"
  ],
  "MechStratagems": [
    "EXO-45 Patriot Exosuit",
    "EXO-49 Emancipator Exosuit"
  ],
};

export default conf;
